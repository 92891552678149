import React, { useState } from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro';
import Footer from 'components/footers/FiveColumnWithInputForm.js';
import { SectionHeading } from 'components/misc/Headings';
import MainFeature2 from 'components/features/TwoColWithSteps.js';
import { valores } from 'Content/Nosotros/Valores';
import { infoNosotros } from 'Content/Nosotros/Nosotros';

import visionImg from 'images/NVO/Mision.png';


const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-800`;
const SubheadingRow = tw.h3`font-bold text-center md:text-left text-[#F7931E] text-[20px] my-4`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-800 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url('${props.imageSrc}');
    `}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-[#2D3748] text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-[#F7931E] after:w-8 `;
const Title = tw.div`mt-1 font-black text-2xl text-gray-800 group-hover:text-[#2D3748] transition duration-300`;
const Description = tw.div``;

const ContentValues = tw.div`py-8 px-4 mx-auto max-w-5xl  lg:py-6 flex flex-wrap justify-center`

const Values = tw.article`grid 2xl:grid-cols-3 lg:grid-cols-2 gap-y-8 gap-x-4 `

const Schedule = tw.div`rounded px-4 py-2 mt-[20px] bg-[#2d3748] text-sm block text-gray-400 max-w-md hocus:scale-105`

const ValueDescription = tw.p`my-4`;


export default ({
  headingText = 'Nosotros',
  posts = infoNosotros
}) => {
  const [visible, setVisible] = useState(7);
  const onLoadMoreClick = () => {
    setVisible((v) => v + 6);
  };
  return (
    <AnimationRevealPage>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Posts>
            {posts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post
                  className='group'
                  as='a'
                  href={post.url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Image imageSrc={post.imageSrc}></Image>
                  <Info>
                    <Category>{post.category}</Category>
                    <Title>{post.title}</Title>
                    {post.featured && post.description && (
                      <Description>{post.description}</Description>
                    )}
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          <Category>Presiona la imagen para saber nuestra ubicación</Category>
          <MainFeature2 imageSrc={visionImg} />
        </ContentWithPaddingXl>
      </Container>
      <ContentValues >
        <Heading>Nuestros valores</Heading>
        <Values >
          {valores.map(({title, description}) => (
            <Schedule>
              <SubheadingRow >{title}</SubheadingRow>
              <ValueDescription>
                {description}
              </ValueDescription>
            </Schedule>
          ))}
        </Values>
      </ContentValues>
      <Footer />
    </AnimationRevealPage>
  );
};
