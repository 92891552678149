let ubicacionPrincipal = window.pageYOffset;
window.onscroll = function () {
  let Desplazamiento_Actual = window.pageYOffset;
  if (ubicacionPrincipal >= Desplazamiento_Actual) {
    document.querySelector(".DesktopNav").style.top = "0";
  } else {
    document.querySelector(".DesktopNav").style.top = "-110px";
  }
  ubicacionPrincipal = Desplazamiento_Actual;
};


