import React from "react";
import { NavLink } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "images/NVO/Nvo.svg";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "images/instagram-icon.svg";
import { ReactComponent as WhatsappIcon } from "images/whatsapp-icon.svg";

const Container = tw.div`relative text-gray-700 mb-[-34px] -mx-8 px-1 py-10 lg:py-[15px] `;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10 mt-5`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-20`;

const ColumnHeading = tw.h5`uppercase font-bold text-white`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`mr-3 border-b-2 border-transparent hocus:border-[#F7931E] pb-1 transition duration-300 text-white`;


const Divider = tw.div`my-5  border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start `;
const LogoImg = tw.img`w-10`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
let activeLink = 'active-link'
export default () => {
  return (
    <Container className="container">
      <div className="wave"></div>
      <Content >
        <SixColumns>
          <Column>
            <ColumnHeading>Pagina</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link><NavLink className={({isActive})=> isActive? activeLink : undefined}  to="/">Inicio</NavLink></Link>
                <Link><NavLink className={({isActive})=> isActive? activeLink : undefined}  to="/Soluciones">Soluciones</NavLink></Link>
                <Link><NavLink className={({isActive})=> isActive? activeLink : undefined}  to="/Nosotros">Nosotros</NavLink></Link>
                <Link><NavLink className={({isActive})=> isActive? activeLink : undefined}  to="/Contacto">Hablemos</NavLink></Link>

              </LinkListItem>
            </LinkList>
          </Column>
        </SixColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={LogoImage} />
          </LogoContainer>
          <CopywrightNotice>&copy; 2022 NVO Systems. Todos los derechos reservados.</CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/nvosystems" target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/nvo_systems" target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </SocialLink>
            <SocialLink href="https://api.whatsapp.com/send?phone=+573008113256&text=Hola,%20%C2%BFqu%C3%A9%20tal%20NVOsystems?" target="_blank" rel="noopener noreferrer">
              <WhatsappIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
    </Container>
  );
};
