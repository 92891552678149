export const valores = [
    {
      title: 'Innovación',
      description:
        'Impulsamos constantemente la innovación, adoptando tecnologías emergentes y soluciones creativas para abordar los desafíos empresariales.',
    },
    {
      title: 'Compromiso con el cliente',
      description:
        'Nuestros clientes son nuestra prioridad. Nos comprometemos a comprender sus necesidades, superar sus expectativas y construir relaciones duraderas basadas en la confianza y la transparencia.',
    },
    {
      title: 'Excelencia',
      description:
        'Buscamos la excelencia en todo lo que hacemos, desde la  consultoría hasta la implementación de soluciones. Nos esforzamos por proporcionar servicios y productos de calidad que destaquen en la industria.',
    },
    {
      title: 'Colaboración',
      description:
        'Fomentamos un entorno colaborativo, tanto internamente como con nuestros clientes. Creemos que el trabajo en equipo y la colaboración son fundamentales para el éxito mutuo.',
    },
    {
      title: 'Adaptabilidad',
      description:
        ' Nos adaptamos rápidamente a las cambiantes dinámicas empresariales y tecnológicas. La agilidad y la flexibilidad son esenciales para ofrecer soluciones efectivas en un mundo en constante evolución.',
    },
  ];