import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Slider from "components/testimonials/TwoColumnWithImage.js";

const MainSty = tw.div`
  mt-[10px] 
`
const Subheading = tw.span`uppercase tracking-wider text-sm text-green-800`;
export default () => {
  return (
    <AnimationRevealPage>
       <MainSty >
        <Slider/>

        <Footer />
      </MainSty>
    </AnimationRevealPage>
  );
};
