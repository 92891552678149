import Nvo from "images/slider/nvoInicio.png"
import ConsultoriaTi from "images/slider/Nvo.png"
import A2 from "images/slider/A2.png"
import Hardware from "images/slider/hardwareRecurso 15 (1).png";
import Ia from "images/slider/IARecurso 17.png"

export const testimonials = [
    {
      subheading: "Sobre NVO | Systems",
      heading: "Eficiencia empresarial a través de la tecnología",
      description: "En NVO, abrazamos la innovación tecnológica para potenciar la eficiencia empresarial. Nos dedicamos a transformar la manera en que las empresas operan, implementando soluciones avanzadas que impulsan la eficiencia, la productividad y el crecimiento sostenible",
      primaryButtonText: "Nosotros",
      primaryButtonUrl: "/nosotros",
      imageSrc: Nvo,
      buttonRounded: false,
      imageRounded: true,
      imageBorder: false,
      imageShadow: false,
      imageCss: null,
      imageDecoratorBlob: false,
      imageDecoratorBlobCss: null,
      textOnLeft: true
    },
    {
      subheading: "Consultorias Ti",
      heading: "Innovación tecnologica para impulsar el éxito empresarial",
      description: "NVO ofrece servicios de consultoría en tecnologías de la información para ayudar a las empresas a optimizar sus sistemas y procesos, asegurando un enfoque eficiente y estratégico en el uso de la tecnología.",
      primaryButtonText: "Servicios",
      primaryButtonUrl: "/servicios",
      imageSrc: ConsultoriaTi,
      buttonRounded: false,
      imageRounded: true,
      imageBorder: false,
      imageShadow: false,
      imageCss: null,
      imageDecoratorBlob: false,
      imageDecoratorBlobCss: null,
      textOnLeft: true
    },
    {
      subheading: "A2: Eficiencia Empresarial Garantizada",
      heading: "distribución y soporte a2 sofway",
      description: "Somos distribuidores de a2softway, ofrecemos soluciones integrales para una gestión eficaz de operaciones empresariales. Desde el manejo detallado de inventarios y ventas hasta la implementación de soluciones en facturación y nómina electrónica.",
      primaryButtonText: "Servicios",
      primaryButtonUrl: "/servicios",
      imageSrc: A2,
      buttonRounded: false,
      imageRounded: true,
      imageBorder: false,
      imageShadow: false,
      imageCss: null,
      imageDecoratorBlob: false,
      imageDecoratorBlobCss: null,
      textOnLeft: true
    },

    {
      subheading: "Equipos Empresariales de Calidad",
      heading: "Distribución de equipos empresariales",
      description: "Distribuimos hardware empresarial, ofreciendo una amplia gama de equipos y dispositivos de alta calidad diseñados para satisfacer las necesidades específicas de las empresas. Desde impresoras para etiquetado y marcado de productos hasta lectores de códigos de barras, impresoras POS, impresoras de tarjetas y computadores corporativos, proporcionamos soluciones tecnológicas que potencian la eficiencia operativa de tu empresa.",
      primaryButtonText: "Servicios",
      primaryButtonUrl: "/servicios",
      imageSrc: Hardware,
      buttonRounded: false,
      imageRounded: true,
      imageBorder: false,
      imageShadow: false,
      imageCss: null,
      imageDecoratorBlob: false,
      imageDecoratorBlobCss: null,
      textOnLeft: true
    },

    {
      subheading: "IA que transforma tu empresa",
      heading: "Implementación de inteligencia artificial en tu empresa",
      description: "Implementamos inteligencia artificial en soluciones empresariales, mejorando eficiencia e innovación. Somos parte del equipo creador de Orbit, un asistente virtual que potencia la atención al cliente. En NVO, llevamos la vanguardia de la inteligencia artificial a tu empresa, transformando la manera en que interactúas con clientes y gestionas operaciones.",
      primaryButtonText: "Servicios",
      primaryButtonUrl: "/servicios",
      imageSrc: Ia,
      buttonRounded: false,
      imageRounded: true,
      imageBorder: false,
      imageShadow: false,
      imageCss: null,
      imageDecoratorBlob: false,
      imageDecoratorBlobCss: null,
      textOnLeft: true
    },
  ];