import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import tw from "twin.macro";
import styled from "styled-components";
import { motion } from "framer-motion";
import { SectionDescription } from "components/misc/Typography.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { services } from "Content/Soluciones/Services";
import { products } from "Content/Soluciones/Products";



const TabsControl = tw.div`flex flex-wrap px-2 py-2 rounded leading-none mt-12 xl:mt-0 `;
const TabControl = styled.div`
  ${tw`cursor-pointer px-3 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base bg-[#2d3748] sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700 hocus:bg-[#F7931E] hocus:text-gray-200`}
  }
  ${props => props.active && tw`bg-[#F7931E] w-[140px]! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`justify-center mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const HeaderRow = tw.div`flex pt-20 justify-center items-center flex-col xl:flex-row`;

const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const HeaderGrid = tw(SectionHeading)``;
const Description = tw(SectionDescription)`text-center`;

const PopularPostsContainer = tw.div`flex flex-wrap justify-center pb-10 px-5`;
const PostsContainer = tw.div`mt-12`;
const Post = tw(motion.a)`block sm:max-w-sm cursor-pointer mb-16 last:mb-0 sm:mb-0 sm:odd:mr-8 lg:mr-8 xl:mr-16`;
const Image = styled(motion.div)(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-[400px] bg-cover bg-center bg-no-repeat`
]);
const Title = tw.h5`mt-6 text-xl font-bold transition duration-300 group-hover:text-[#F7931E]`;
const DescriptionContainer = tw.p`mt-2 font-medium text-secondary-100 leading-loose text-sm`;

export default ({
  heading = <>Tecnologia para el <span tw="text-[#F7931E]">éxito</span> empresarial</>,
  description = "En NVO, entendemos que cada empresa es única, con desafíos específicos y metas particulares. Nuestra dedicación es ofrecer soluciones tecnológicas personalizadas que transformen la manera en que operan los negocios y potencien el crecimiento sostenible. Desde la consultoría estratégica hasta la implementación de tecnologías de vanguardia, nuestras soluciones están diseñadas para mejorar la eficiencia, impulsar la innovación y elevar la productividad de su empresa.",
  tabs = {
    Servicios: services,
    //Productos: products
  }
  

}) => {

  const postBackgroundSizeAnimation = {
    rest: {
      backgroundSize: "100%"
    },
    hover: {
      backgroundSize: "110%"
    }
  };

  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <AnimationRevealPage>
      <Container>
        <ContentWithPaddingXl>
          <HeaderContainer>
            <HeaderGrid>{heading}</HeaderGrid>
            <Description>{description}</Description>
          </HeaderContainer>
          <HeaderRow>
            <TabsControl>
              {Object.keys(tabs).map((tabName, index) => (
                <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                  {tabName}
                </TabControl>
              ))}
            </TabsControl>
          </HeaderRow>

          {tabsKeys.map((tabKey, index) => (
            <TabContent
              key={index}
              variants={{
                current: {
                  opacity: 1,
                  scale: 1,
                  display: "flex",
                },
                hidden: {
                  opacity: 0,
                  scale: 0.8,
                  display: "none",
                }
              }}
              transition={{ duration: 0.4 }}
              initial={activeTab === tabKey ? "current" : "hidden"}
              animate={activeTab === tabKey ? "current" : "hidden"}
            >
              {tabs[tabKey].map((post, index) => (
                <PopularPostsContainer>
                  <PostsContainer>
                    <Post key={index} href={post.url} className="group" initial="rest" whileHover="hover" animate="rest" target="_blank" rel="noopener noreferrer">
                      <Image
                        transition={{ duration: 0.3 }}
                        variants={postBackgroundSizeAnimation}
                        imageSrc={post.postImageSrc}
                      />
                      <Title>{post.title1}</Title>
                      <DescriptionContainer>{post.description1}</DescriptionContainer>
                      <Title>{post.title2}</Title>
                      <DescriptionContainer>{post.description2}</DescriptionContainer>
                      <Title>{post.title3}</Title>
                      <DescriptionContainer>{post.description3}</DescriptionContainer>
                    </Post>
                  </PostsContainer>
                </PopularPostsContainer>
              ))}
            </TabContent>
          ))}
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
