import consultorias from "images/servicios/consultorias.png";
import a2 from "images/servicios/A2.png";
import software from "images/servicios/software personalizado.png"
import tv from "images/servicios/mantenimiento.png";
import ia from "images/servicios/IARecurso 17.png"
import investigacion from "images/servicios/laboratorio.png"
import seguridad from "images/servicios/SeguridadRecurso 3.png"

export const services = [
    {
      postImageSrc: consultorias,
      title1: "Consultorias en Tecnologias de la información(TI)",
      description1:
        "Nuestra consultoría en TI va más allá de la tecnología básica. Ofrecemos asesoramiento estratégico para alinear sus objetivos empresariales con soluciones tecnológicas efectivas. Desde la evaluación de necesidades hasta la implementación, le guiamos en cada paso del camino para maximizar el valor de sus inversiones en tecnología. No se trata solo de tecnología; se trata de cómo la tecnología puede impulsar su negocio hacia el éxito. Nuestra consultoría en TI va más allá de la implementación de soluciones; le ofrecemos asesoramiento estratégico para alinear sus objetivos empresariales con tecnologías efectivas. Trabaje con nosotros para aprovechar al máximo sus inversiones en tecnología y transformar su visión empresarial en realidad.",
      url: "https://api.whatsapp.com/send?phone=+573008113256&text=Hola, ¿qué tal NVOsystems? Quiero mas informacion del servicio de televisiores."
    },
    {
      postImageSrc: a2,
      title1: " Distribución y Soporte de A2 Softway",
      description1:
        "Optimice de manera integral sus operaciones con A2 Softway, una solución de vanguardia diseñada para potenciar la eficiencia de su empresa. Más allá de la simple distribución, nos comprometemos a ofrecer un soporte continuo, asegurando que su inversión no solo sea exitosa, sino que evolucione y prospere con sus necesidades cambiantes. Con A2 Softway, obtendrá un conjunto completo de herramientas que le permitirán gestionar de manera eficaz cada aspecto de su negocio. Desde el manejo de inventarios hasta la facturación electrónica, la nómina electrónica, la gestión de clientes, proveedores, cuentas, informes y mucho más. Ponemos a su disposición una solución integral que se adapta a la medida de sus desafíos empresariales, proporcionándole las herramientas necesarias para alcanzar y superar sus objetivos de éxito. Simplificamos la complejidad para que pueda enfocarse en lo que realmente importa: hacer crecer su empresa de manera efectiva y sostenible.",
      url: "https://api.whatsapp.com/send?phone=+573008113256&text=Hola, ¿qué tal NVOsystems? Quiero mas informacion del servicio de consolas."
    },
    {
      postImageSrc: ia,
      title1: "Implementación de Inteligencia Artificial en Soluciones Empresariales",
      description1:
        "Transforme su empresa con nuestra implementación de Inteligencia Artificial (IA). Desde la automatización de tareas hasta la toma de decisiones basadas en datos, nuestra IA lleva su negocio a nuevas alturas. Ofrecemos una ventaja competitiva y eficiencia sin igual, permitiéndole anticipar, adaptarse y destacarse en su industria. Con NVO, no solo adopta tecnología avanzada, sino que da el paso hacia un futuro empresarial más inteligente y ágil. La implementación de IA es más que una actualización, es una evolución estratégica para su éxito.",
      url: "https://api.whatsapp.com/send?phone=+573008113256&text=Hola, ¿qué tal NVOsystems? Quiero mas informacion del servicio de computo."
    },
    {
      postImageSrc: software,
      title1: "Desarrollo de Software Personalizado",
      description1:
        "Su empresa es única, y su software debería serlo también. Con nuestro desarrollo de software personalizado, transformamos sus ideas en soluciones digitales que se integran perfectamente en su flujo de trabajo, proporcionándole la agilidad y la eficiencia que su empresa merece.",
      url: "https://api.whatsapp.com/send?phone=+573008113256&text=Hola, ¿qué tal NVOsystems? Quiero mas informacion del servicio de computo."
    },
    {
      postImageSrc: seguridad,
      title1: "Seguridad Informática",
      description1:
        "En el panorama digital actual, donde la seguridad es esencial, nuestras soluciones de seguridad informática son más que un escudo: son su garantía de protección y tranquilidad. No solo resguardamos sus datos, sino que anticipamos y neutralizamos las amenazas cibernéticas en constante evolución. Con NVO, su negocio está no solo protegido, sino fortificado, permitiéndole operar con confianza en un entorno digital en rápida transformación. La seguridad no es solo un servicio; es nuestra promesa de salvaguardar su éxito en el mundo digital.",
      url: "https://api.whatsapp.com/send?phone=+573008113256&text=Hola, ¿qué tal NVOsystems? Quiero mas informacion del servicio de computo."
    },
    {
      postImageSrc: consultorias,
      title1: "Capacitación y Desarrollo",
      description1:
        "La tecnología es tan efectiva como las personas que la utilizan. Con nuestros programas de capacitación y desarrollo, equipamos a su equipo con habilidades tecnológicas avanzadas, garantizando una transición suave y maximizando el retorno de inversión en las soluciones implementadas.",
      url: "https://api.whatsapp.com/send?phone=+573008113256&text=Hola, ¿qué tal NVOsystems? Quiero mas informacion del servicio de computo."
    },
    {
      postImageSrc: tv,
      title1: "Soporte Técnico Remoto",
      description1:
        "Cuando se trata de mantener su sistema en perfecto funcionamiento, la distancia no es un problema. Con nuestro servicio de soporte técnico remoto, traemos la solución directamente a su pantalla. Desde la resolución de problemas informáticos cotidianos hasta asistencia técnica especializada, estamos a solo un clic de distancia. No pierda tiempo esperando a que llegue alguien a su ubicación; nosotros le brindamos asistencia instantánea. Con NVO, su eficiencia no espera. Permita que nuestro soporte técnico remoto se encargue de los problemas para que pueda concentrarse en hacer crecer su negocio.",
      url: "https://api.whatsapp.com/send?phone=+573008113256&text=Hola, ¿qué tal NVOsystems? Quiero mas informacion del servicio de computo."
    },
    {
      postImageSrc: investigacion,
      title1: "Investigación y Desarrollo",
      description1:
        "En NVO, la investigación y el desarrollo no son solo conceptos; son el motor que impulsa su ventaja competitiva. Nos encontramos en la vanguardia de la tecnología, explorando constantemente nuevas fronteras para mantener su empresa a la cabeza de la evolución digital. Con nuestras iniciativas de investigación y desarrollo, no solo le ofrecemos soluciones avanzadas, sino que le brindamos la oportunidad de estar a la vanguardia de la innovación. Acceda a tecnologías emergentes antes que la competencia y benefíciese de soluciones personalizadas diseñadas con las últimas tendencias del mercado.",
      url: "https://api.whatsapp.com/send?phone=+573008113256&text=Hola, ¿qué tal NVOsystems? Quiero mas informacion del servicio de computo."
    },
]