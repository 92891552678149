import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/NVO/Contacto.png";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const ButtonContent = tw.div`flex flex-col text-center`;

const Subheading = tw(SubheadingBase)`text-center md:text-left text-[#2D3748]`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`


const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-green-800`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-6`

export default ({
  subheading = "Descubre más",
  heading = <>Conversa con <span tw="text-[#F7931E]">nosotros</span><wbr/></>,
  description = "¡Bienvenido a NVO! Aquí, la puerta está abierta para tus preguntas, ideas y conversaciones sobre cómo nuestras soluciones tecnológicas pueden potenciar tu empresa. Ya sea por correo electrónico o a través de WhatsApp, estamos listos para responder tus consultas y explorar juntos el camino hacia el éxito. ¡Inicia la conversación y descubre cómo NVO puede ser el impulso que tu empresa necesita!",
  submitButtonText1 = "Contáctanos a nuestro Email",
  submitButtonText2 = "Contáctanos a nuestro WhatsApp",
  href1 = "mailto:empresarios@nvo.systems",
  href2 = "https://api.whatsapp.com/send?phone=+573008113256&text=Hola,%20%C2%BFqu%C3%A9%20tal%20NVOsystems?",
  textOnLeft = true,
}) => {
  
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <ButtonContent>
              <SubmitButton as="a" href={href1} type="submit" target="_blank" rel="noopener noreferrer">{submitButtonText1}</SubmitButton>
              <SubmitButton as="a" href={href2} type="submit" target="_blank" rel="noopener noreferrer">{submitButtonText2}</SubmitButton>
            </ButtonContent>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
