import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import { contactDetails } from "Content/Contacto/ContactDetails";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;
const Schedule = tw.p`rounded px-4 py-2 mt-[20px] bg-[#2d3748] text-sm block text-gray-400`;

export default () => {
  return (
    <AnimationRevealPage>
      <ContactUsForm />
      <ContactDetails
        cards={[
          {
            title: "Tuluá",
            description: (
              <>
                <Address>
                  <AddressLine>{contactDetails.address}</AddressLine>
                  <AddressLine>{contactDetails.neighborhood}</AddressLine>
                  <AddressLine>{contactDetails.city}</AddressLine>
                </Address>
                <Email>{contactDetails.email}</Email>
                <Phone>{contactDetails.phone}</Phone>
                <Phone>{contactDetails.cellPhone}</Phone>
                <Schedule>Nuestro equipo está disponible para ti de lunes a viernes, <span tw="text-[#F7931E]">de 9 am a 5 pm.</span> ¡Esperamos atenderte pronto!"</Schedule>
              </>
            )
          }
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
