import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";

import Header from "./components/headers/light"
import Servicios from "components/blogs/Services.js";
import Inicio from "pages/Main.js";
import Contacto from "pages/ContactUs.js";
import Nosotros from "pages/InformationUs.js";

import { BrowserRouter as Router, Routes, Route} from "react-router-dom";

export default function App() {
  return (
    
    <Router>
      <Header/>
      <Routes>
        <Route path="/Nosotros" element={<Nosotros/>}/>
        <Route path="/Soluciones" element={<Servicios/>}/>
        <Route path="/Hablemos" element={<Contacto/>}/>
        <Route path="/" element={<Inicio/>}/>
      </Routes>
    </Router>
  );
}
