import React from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import './NavSroll'

import logo from "../../images/NVO/Nvo.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import "../../styles/globalStyles.css"

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl  my-[25px]
`;

export const NavLinks = tw.div`inline-block ml-[50%] lg:ml-[30%]`;



export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  // ${tw`flex items-center font-bold border-b-0 text-3xl! mr-20`};

  img {
    ${tw`w-20 sm:w-24 ml-3 `}
  }
`;



export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between bg-[#2D3748] p-6 -mt-8`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-white transition duration-200
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-[90px] p-8 text-center rounded-lg text-gray-900 bg-[#2D3748]`}
  ${NavLinks} {
    ${tw`flex flex-col items-center w-full m-auto`}
  }
`);

export const ItemNav = tw.div`
  text-lg my-2 lg:text-sm lg:mx-4 lg:my-0 text-white
  font-semibold tracking-wide transition duration-300
  pb-1 border-transparent hover:border-gray-500 hocus:text-[#F7931E] inline-block 
`;

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 place-content-around items-center fixed border-b-[1px] bg-[#1F324F] w-[100%] z-50  rounded-b-lg -mx-10  p-4 w-[100vw]
`;

export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg" }) => {

  let activeLink = 'active-link'
  const defaultLinks = [
    <NavLinks key={1} className='navlinks'>
      <ItemNav>
        <NavLink className={({isActive})=> isActive? activeLink : undefined}  to="/">
          Inicio
        </NavLink>
      </ItemNav>
      
      <ItemNav>
        <NavLink className={({isActive})=> isActive? activeLink : undefined}  to="/Soluciones">
          Soluciones
        </NavLink>
      </ItemNav>
      <ItemNav>
        <NavLink className={({isActive})=> isActive? activeLink : undefined}  to="/Nosotros">
          Nosotros
        </NavLink>
      </ItemNav>
      
      <ItemNav>
        <NavLink className={({isActive})=> isActive? activeLink : undefined}  to="/Hablemos">Hablemos
        </NavLink>
      </ItemNav>
      
    </NavLinks>
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img  src={logo} alt="logo" />
    </LogoLink>
  );
 

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks className="DesktopNav" css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
        <ItemNav>
        </ItemNav>
      </DesktopNavLinks>

      <MobileNavLinksContainer className="mobile-nav" css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
          {links}
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
          {showNavLinks ? <CloseIcon tw="w-6 h-6 " /> : <MenuIcon tw="w-6 h-6" />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};


const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex `,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex mx-[.5px]`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex `,
    mobileNavLinksContainer: tw`lg:hidden`
  },
};