import nosotros from 'images/NVO/img.png';

export const infoNosotros = [
    {
      imageSrc: nosotros,
      category: 'Forjando sueños',
      title: 'Transformando realidades',
      description:
        'En el corazón de Tuluá, en 2015, nació NVO: una chispa de innovación y dedicación arraigada en la belleza de una ciudad llena de emprendedores. Nos enorgullece ser arquitectos de soluciones, soñadores en acción y constructores de futuros vibrantes. En NVO, cada desafío es una oportunidad, cada obstáculo es un trampolín hacia el éxito. Nuestro equipo dinámico se adapta a las cambiantes mareas tecnológicas con agilidad y visión. Más que consultores, estamos comprometidos apasionadamente con la excelencia, la satisfacción del cliente y el progreso sostenible. En este viaje, convertimos sueños en códigos, ideas en innovaciones y desafíos en logros. Cada proyecto es una nueva página en nuestro libro; cada cliente, un socio en esta emocionante travesía.',
      url: 'https://www.google.com/maps/place/NVO/@4.0754681,-76.1870504,19.98z/data=!4m14!1m7!3m6!1s0x8e39c44abb466fa1:0x297459839f81ebf5!2sNVO!8m2!3d4.0756919!4d-76.1868749!16s%2Fg%2F11gc9gs3kz!3m5!1s0x8e39c44abb466fa1:0x297459839f81ebf5!8m2!3d4.0756919!4d-76.1868749!16s%2Fg%2F11gc9gs3kz?entry=ttu',
      featured: true,
    },
  ]