import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Modal from "react-modal";

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
</style>

Modal.setAppElement("#root");

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
