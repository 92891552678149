import React, { useRef, useEffect, useCallback } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg"
import { ReactComponent as ArrowLeftIcon } from "../../images/arrow-left-2-icon.svg";
import { ReactComponent as ArrowRightIcon } from "../../images/arrow-right-2-icon.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-5.svg";
import { testimonials } from "Content/Slider/Testimonials";

import "slick-carousel/slick/slick.css";


const TestimonialSliderContainer = tw.div` mt-[10px] lg:h-[80vh] sm:h-[65vh] align-middle `;
const TestimonialSlider = styled(Slider)``;
const Testimonial = tw.div` -mt-12 flex! flex-col items-center md:items-stretch md:flex-row md:justify-center outline-none`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageContainer = tw(Column)`w-full md:w-5/12 flex relative align-middle mt-32`;
const TextContainer = tw.div`w-full px-16 lg:py-2 sm:mt-10  text-center md:text-left`;

const SliderControlButtonContainer = styled.div`
  ${tw`absolute top-0 h-full flex items-end md:items-center z-20`}
  button {
    ${tw`text-secondary-500 hover:text-green-800 focus:outline-none transition duration-300 transform hover:scale-125 transform -translate-y-2/3 md:translate-y-0`}
    svg {
      ${tw`w-8`}
    }
  }
`;

const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 top-0 -z-10 text-green-800 opacity-15 transform translate-x-2/3 translate-y-8 `;


const TextColumn = styled(Column)(props => [
  tw`md:w-6/12  md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
  tw`w-8/12 m-auto`
]);

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
])

const Subheading = tw(SubheadingBase)`text-center md:text-left text-[#F7931E] mt-[25%]`;
const Heading = tw(
  SectionHeading
)` font-black text-left text-2xl  sm:text-lg lg:text-lg text-center md:text-left leading-tight 2xl:text-[26px]`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg 2xl:text-[20px] font-medium leading-relaxed text-secondary-100`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

export default () => {
  const NextArrow = ({ currentSlide, slideCount, ...props }) => (
    <SliderControlButtonContainer tw="right-0">
      <button {...props}>
        <ArrowRightIcon />
      </button>
    </SliderControlButtonContainer>
  );

  const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
    <SliderControlButtonContainer tw="left-0 ">
      <button {...props}>
        <ArrowLeftIcon />
      </button>
    </SliderControlButtonContainer>
  );

  const slideshow = useRef(null);
  const intervaloSlideshow = useRef(null);

  return (
        <TestimonialSliderContainer className="content-slidbar">
          <TestimonialSlider autoplay={setInterval(() => {<NextArrow />}, 8000)} nextArrow={<NextArrow />} prevArrow={<PreviousArrow />}>
            {testimonials.map((testimonial, index) => (
              <Testimonial key={index}>
                <ImageContainer>
                  <Image  src={testimonial.imageSrc} imageBorder={testimonial.imageBorder} imageShadow={testimonial.imageShadow} imageRounded={testimonial.imageRounded} />
                  {testimonial.imageDecoratorBlob && <DecoratorBlob css={testimonial.imageDecoratorBlobCss} />}
                </ImageContainer>
                <TextColumn textOnLeft={testimonial.textOnLeft}>
                  <TextContainer>
                    <Subheading>{testimonial.subheading}</Subheading>
                    <Heading>{testimonial.heading}</Heading>
                    <Description>{testimonial.description}</Description>
                    <PrimaryButton buttonRounded={testimonial.buttonRounded} as="a" href={testimonial.primaryButtonUrl}>
                      {testimonial.primaryButtonText}
                    </PrimaryButton>
                  </TextContainer>
                </TextColumn>
              </Testimonial>
            ))}
          </TestimonialSlider>
        </TestimonialSliderContainer>
  );
};
